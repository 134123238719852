<script>
let previousDay = undefined;
export default {
    name: "EmailEvent",
    props: ['event', 'lastRefresh'],
    methods: {
        format(Day, Time) {
            if (previousDay == Day) {
                if (Time != 0) {
                    return ' ; '+Time
                }
                return ''
            }
            previousDay = Day
            return ' '+Day+' '+Time
        },
        getOpens(Opens) {
            if (!Opens) {
                return []
            }
            let days = []
            let prev = undefined
            // build the day and times arrays
            Opens.forEach( x => {
                let e = { Time: x.Time, Acknowledged: x.Acknowledged, Timestamp: x.Timestamp }
                if (prev && (prev.Day == x.Day)) {
                    prev.Acknowledged &= x.Acknowledged
                    prev.Timestamp = Math.max(prev.Timestamp, x.Timestamp)
                } else {
                    prev = { Day: x.Day, Acknowledged: x.Acknowledged, Timestamp: x.Timestamp, Times: [] }
                    days.push(prev)
                }
                prev.Times.push(e)
            } )
            // sort on times ascending
            days.forEach(x => x.Times.sort((a,b) => a.Timestamp-b.Timestamp))
            // sort on day descending
            days.sort((a,b) => b.Timestamp-a.Timestamp)
            // return
            return days
        }
    }
}
</script>

<style scoped>
div > img {
    max-width: 32px;
}
div.adapt {
    display: contents;
}
</style>

<template>
<div>
    <div class="row bg-secondary border-rounded mt-4 text-white d-flex align-items-center mb-1">
        <div class="col-auto">
            <img v-if="event.Type == 'mailjet'" src="../assets/mailjet.png"/>
            <img v-else-if="event.Type == 'mailersend'" src="../assets/mailersend.png"/>
            <span v-else>{{ event.Type }}</span>
        </div>
        <div class="col-auto">
            <span v-if="event.Sender" class="h5">{{ event.Sender }}</span>
            <font-awesome-icon icon="fa-solid fa-arrow-right" class="mx-1"/>
            <span class="h5">{{ event.Recipient }}</span>
        </div>
        <div v-if="event.Subject" class="col-2 text-truncate"><kbd>{{ event.Subject }}</kbd></div>
        <div class="col-auto">
            <span v-if="event.Sent" class="m-1 p-1 rounded" :class="{ 'fw-bold': !event.Acknowledged, 'bg-danger': event.Sent.Timestamp > lastRefresh }" >
                Envoi {{ event.Sent.Day }} {{ event.Sent.Time}}
            </span>
            <span v-else>Pas envoyé</span>
            <span v-if="event.Delivered" class="m-1 p-1 rounded" :class="{ 'fw-bold': !event.Acknowledged, 'bg-danger': event.Delivered.Timestamp > lastRefresh }">
                Délivré {{ event.Delivered.Day }} {{ event.Delivered.Time }}
            </span>
        </div>
        <div class="col row">
            <div class="d-flex flex-row-reverse">
            <button v-if="!event.Acknowledged" class="btn btn-success" @click="$emit('acknowledge', event)" >
                <font-awesome-icon icon="fa-solid fa-check" />
                Acquitter
            </button>
            </div>
        </div>
    </div>

    <div v-if="event.Bounce" class="row bg-warning" >
        <div class="col-2 fw-bold">
            Rebond
        </div>
        <div class="col" :class="{ 'fw-bold': ! event.Bounce.Acknowledged }" >
            {{ event.Bounce.when }}
            <b>{{ event.Bounce.message }}</b>
        </div>
    </div>

    <div v-if="event.Blocked" class="row bg-danger" >
        <div class="col-2 fw-bold">
            Bloqué
        </div>
        <div class="col" :class="{ 'fw-bold': ! event.Blocked.Acknowledged }" >
            {{ event.Blocked.when }}
            <b>{{ event.Blocked.message }}</b>
        </div>
    </div>

    <div v-if="event.Spam" class="row bg-danger" >
        <div class="col-2 fw-bold">
            Signalé comme spam
        </div>
        <div class="col" :class="{ 'fw-bold': ! event.Spam.Acknowledged }" >
            {{ event.Spam.when }}
            <b>{{ event.Spam.by }}</b>
        </div>
    </div>

    <div v-if="event.Open.length > 0" class="row" >
        <div v-if="event.Open.length > 1" class="col">{{ event.Open.length }} ouvertures</div>
        <div v-else class="col">{{ event.Open.length }} ouverture</div>
    </div>
    <div class="row" v-for="day in getOpens(event.Open)" :key="day">
        <div class="col-auto" :class="{ 'fw-bold': ! day.Acknowledged , 'bg-danger': (day.Timestamp) && (day.Timestamp > lastRefresh) }" >
            {{ day.Day }}
        </div>
        <div class="col adapt">
            <span v-for="time in day.Times" :key="time" class="pe-1" :class="{ 'fw-bold': ! time.Acknowledged , 'bg-danger': (time.Timestamp) && (time.Timestamp > lastRefresh) }" >
                {{time.Time}}
            </span>
        </div>
    </div>

</div>
</template>

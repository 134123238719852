<template>
  <div class="container-fluid">
    <AuthentView v-if="!Authenticated()" :loading="loading" @login="doLogin"></AuthentView>
    <div v-else>
      <nav class="navbar navbar-light sticky-top bg-light row">
        <nav class="h2 col-auto">
          Evénements SMTP
        </nav>

        <nav class="navbar-text col">
          <form
            class="bg-secondary row rounded navbar-text"
            onsubmit="return false;"
          >
            <div class="input-group col">
              <span class="input-group-text">@</span>
              <input
                v-model="email"
                type="search"
                class="form-control"
                placeholder="Email"
                aria-label="Username"
              >
            </div>

            <div class="bg-light rounded col-auto pt-2 ms-2">
              <input
                id="allFilter"
                v-model="onlyNew"
                type="checkbox"
                class="form-check-input"
              >
              <label
                class="form-check-label ps-2"
                for="allFilter"
              >
                <div style="position: relative">
                  <span :class="{ invisible: !onlyNew }">Nouveaux</span>
                  <span
                    :class="{ invisible: onlyNew }"
                    style="position: absolute;left: 0px;"
                  >Tous</span>
                </div>
              </label>
            </div>

            <div class="col-auto">
              <button
                type="button"
                class="btn btn-primary"
                @click="refresh"
              >
                <font-awesome-icon icon="fa-solid fa-redo" />
                <span
                  v-if="nbUnread > 0"
                  class="badge text bg-danger ms-2"
                  @click.stop="clearNew"
                >{{ nbUnread }}</span>
              </button>
            </div>
          </form>
        </nav>

        <nav class="navbar navbar-light navbar-text col mx-3">
          <span class="row col-12" v-if="!filteredEvents || filteredEvents.length == 0">Aucun email affiché</span>
          <span class="row col-12" v-else-if="filteredEvents.length == 1">1 email affiché</span>
          <span class="row col-12" v-else>{{ filteredEvents.length }} emails affichés</span>
          <span class="row col-12" v-if="!recipients || recipients.length == 0 ">Aucun destinataire</span>
          <span class="row col-12" v-else-if="recipients.length == 1">1 destinataire</span>
          <span class="row col-12" v-else>{{ recipients.length }} destinataires</span>
        </nav>

        <nav class="navbar navbar-light col-auto">
          <button
            type="button"
            class="btn btn-danger"
            alt="Déconnexion"
            @click="logout"
          >
            <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" />
          </button>
        </nav>
      </nav>

      <div v-if="!filteredEvents || (filteredEvents.length == 0)" class="jumbotron h2 text-center" >
        Aucune information à afficher
      </div>
      <div v-else>
        <EmailEvent
          v-for="event in filteredEvents"
          :key="event.GUID"
          :event="event"
          :lastRefresh="last"
          @acknowledge="acknowledge"
        />
      </div>
    </div>

    <vue-notification-list position="top-right" />
    <loading
      :active="loading"
      color="#00d"
      :height="128"
      :width="128"
      loader="spinner"
      :opacity="opacity"
      blur="5px"
    />
  </div>
</template>

<script>
// Base URL
const URLs = {
  login: '/api/login',
  smtpNew: '/api/smtp/new',
  smtpAll: '/api/smtp/all',
  smtpAck: '/api/smtp/ack',
}

/**
 * Retourne une fonction qui, tant qu'elle continue à être invoquée,
 * ne sera pas exécutée. La fonction ne sera exécutée que lorsque
 * l'on cessera de l'appeler pendant plus de N millisecondes.
 * Si le paramètre `immediate` vaut vrai, alors la fonction 
 * sera exécutée au premier appel au lieu du dernier.
 * Paramètres :
 *  - func : la fonction à `debouncer`
 *  - wait : le nombre de millisecondes (N) à attendre avant 
 *           d'appeler func()
 *  - immediate (optionnel) : Appeler func() à la première invocation
 *                            au lieu de la dernière (Faux par défaut)
 *  - context (optionnel) : le contexte dans lequel appeler func()
 *                          (this par défaut)
 */
function debounce(func, wait, immediate, context) {
    var result
    var timeout = null
    return function() {
        var ctx = context || this, args = arguments
        var later = function() {
            timeout = null
            if (!immediate) result = func.apply(ctx, args)
        }
        var callNow = immediate && !timeout
        // Tant que la fonction est appelée, on reset le timeout.
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) result = func.apply(ctx, args)
        return result
    }
}

// import Bootstrap
import 'bootstrap/dist/css/bootstrap.css'

// import loading overlay
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

// the notification toast
import '@dafcoe/vue-notification/dist/vue-notification.css'
import { useNotificationStore } from '@dafcoe/vue-notification'
const { setNotification } = useNotificationStore()

// import EmailEvent component
import EmailEvent from './components/EmailEvent.vue'

// import Authent view
import AuthentView from './views/AuthentView.vue'

export default {
  name: 'App',
  components: {
    Loading,
    EmailEvent,
    AuthentView
},
  data() { return {
    // User informations are stored in this object
    userData: undefined,
    // whether to display only new events or all
    onlyNew: true,
    // value of the email filter
    email: "",
    // Timestamp of the latest event from DB fetech during refresh
    last: 0,
    // Timestamp of the latest event fetched from DB (in periodicCheck)
    lastCheck: 0,
    // number of new events in DB since last refresh
    nbUnread: 0,
    // the interval timer ID is stored in this variable
    timer: undefined,
    // the full list of events fetched
    events: undefined,
    // the list of events to display
    displayEvents: undefined,
    // the filtered list of events
    filteredEvents: undefined,
    // unique list of recipients
    recipients: undefined,
    // if true, displays the loading rotating circle
    loading: false,
    // the opacity of the loading circle background. Initially set to 1
    opacity: 1
  }},

  watch: {
    timer(newTimer, oldTimer) {
      // clean old timer if it was forgotten
      if (typeof(oldTimer) != 'undefined') {
        clearInterval(oldTimer)
      }
    },
    userData(newUser) {
      if (typeof newUser == 'undefined') {
        this.timer = undefined
      } else {
        this.timer = setInterval(this.periodicCheck, 10000)
      }
    },
    email() {
      return this.debouncedEmail()
    }
  },
  mounted() {
    this.init()
  },
  created() {
    // Setting Language in the HTML document
		document.documentElement.setAttribute('lang', '<language_code>')
    // add the function to be debounced on filter onchange event
    this.debouncedEmail = debounce( () => { this.filterByEmail() } , 500)
  },
  methods: {
    Authenticated() {
      return !!this.userData
    },

    filterByEmail() {
      let result = this.displayEvents
      if (this.email) {
        var lcEmail = this.email.toLowerCase()
        result = this.displayEvents.filter((e) => e.Recipient && e.Recipient.toLowerCase().includes(lcEmail))
      }
      // assign event
      this.filteredEvents = result
      // compute recipients
      this.recipients = Array.from(result.reduce((acc,evt) => acc.add(evt.Recipient), new Set()).values())
      // compute number of unread events
      this.nbUnread = result.reduce((nb,evt) => nb + evt.nbUnread(this.last), 0)
    },

    async fetchJson(method, URL, params = {}) {
      const opts = {
        params: undefined,
        useLoading: true,
        autoDisconnect: true,
        ...((typeof params == 'object') ? params : {})
      }
      try {
        if (opts.useLoading) {
          this.loading = true
        }
        const headers = { 
              "Accept": "application/json", 
              Origin: "localhost" 
            }
        const response = await fetch(
          URL, 
          { 
            method: method, 
            credentials: 'include',
            mode: 'cors',
            headers: headers, 
            ...opts.params })
        if ( !response.status ) {
          throw response
        }
        if ( response.status == 403) {
          if ( opts.autoDisconnect ) {
            // set user as not connected
            this.userData = undefined
            // and display a notification
            setNotification({
              message: "Vous avez été déconnecté",
              type: "info"
            })
          }
        }
        if ( !response.json ) {
          throw response
        }
        const json = await response.json()
        if (opts.useLoading) {
          this.loading = false
        }
        if (json.message) {
          return {
            code: response.status,
            message: json.message
          }
        }
        return {
          code: response.status,
          result: json
        }
      } catch (err) {
        // TODO Display unexpected error
        console.log("Caught error ", err)
        if(opts.useLoading) {
          this.loading = false
        }
        if (err.text) {
          const text =await err.text()
          console.log("Text is ", text)
        }
        throw err
      }
    },
    
    async refresh() {
      const { code, result } = await this.fetchJson( "GET", (this.onlyNew ? URLs.smtpNew : URLs.smtpAll ) )
      if (code == 200) {
        // build the event unique array
        this.events = new Map()
        result.forEach(evt => this.events.set(evt.ID, evt))
        // rebuild displayEvents reactive property
        this.buildEvents(true)
      }
    },

    clearNew() {
      this.last = this.lastCheck
      this.nbUnread = 0
    },

    async acknowledge(event) {
      // create form data
      let formData = new FormData()
      formData.append('GUID', event.GUID)
      formData.append('Type', event.Type)
      // send it to server
      try {
        const { code, result } = await this.fetchJson('POST', URLs.smtpAck, { params: { body: formData }})
        if (code != 200) {
          throw "Une erreur s'est produite"
        }
        if (result && result.status && result.status == "OK") {
          // build the list of events to process
          let toDelete = []
          this.events.forEach( (evt, key) => { if (evt.GUID == event.GUID) { toDelete.push(key) } } )
          if (this.onlyNew) {
            // remove events since we display only new events
            toDelete.forEach( k => this.events.delete(k) )
          } else {
            toDelete.forEach( k => this.events.get(k).Acknowledged = true )
          }
          // rebuild events
          this.buildEvents(false)
          // notify the user
          setNotification({
              message: "Email acquitté",
              type: "success"
            })
        } else {
          throw "Réponse inattendue du serveur"
        }
      } catch (e) {
        setNotification({
          message: e.toString(),
          type: "error"
        })
      }
    },

    async logout() {
      await this.fetchJson('DELETE', URLs.login)
    },

    buildEvents(isRefresh) {
      this.loading = true
      // build a new displayEvents array
      var DE = new Map()
      this.events.forEach( event => {
        let row = DE.get(event.GUID)
        if (! row) {
        // non existent, build entry
          row = {
            Sender: event.Sender,
            Recipient: event.Recipient,
            Subject: event.Subject,
            Type: event.Type,
            Acknowledged: true,
            Last: event.Timestamp,
            Day: event.Day,
            Time: event.Time,
            GUID: event.GUID,
            Open: [],
            /*  setters */
            set sent(evt) { this.Sent = evt },
            set delivered(evt) { this.Delivered = evt },
            set open(evt) { this.Open.push(evt) }, /* mailjet specific */
            set opened(evt) { this.Open.push(evt) }, /* mailersend specific */
            set bounce(evt) { this.Bounce = evt },
            set blocked(evt) { this.Blocked = evt },
            set spam(evt) { this.Spam = evt },
            // method to compute number of unread items
            nbUnread(timestamp) {
              let nb = 0
                + ((this.Sent && (this.Sent.Timestamp > timestamp)) ? 1 : 0)
                + ((this.Delivered && (this.Delivered.Timestamp > timestamp)) ? 1 : 0)
                + ((this.Bounce && (this.Bounce.Timestamp > timestamp)) ? 1 : 0)
                + ((this.Blocked && (this.Blocked.Timestamp > timestamp)) ? 1 : 0)
                + ((this.Spam && (this.Spam.Timestamp > timestamp)) ? 1 : 0)
              return this.Open.reduce((nb, evt) => (evt.Timestamp > timestamp) ? (nb+1) : nb , nb)
            }
          }
          DE.set(event.GUID, row)
        }
        if (event.Event in row) {
          row[event.Event] = event
          row.Acknowledged &&= event.Acknowledged
          row.Last = Math.max(event.Timestamp, row.Last)
        } else {
          console.log("Unexpected event ", event)
        }
      })
      // Now sort on Timestamp and affect to displayEvents
      this.displayEvents = Array.from(DE.values()).sort((a,b) => b.Last-a.Last)
      // compute various timestamps value
      this.lastCheck = Math.max(...this.displayEvents.map(x => x.Last))
      if (isRefresh) {
        this.last = this.lastCheck
      }
      // and do a call to filter function
      this.filterByEmail()
      this.loading = false
    },

    async periodicCheck() {
      if (!this.loading) {
        const { code, result } = await this.fetchJson("GET", ( this.onlyNew ? URLs.smtpNew : URLs.smtpAll ) + '/' + this.lastCheck, { useLoading: false } )
        if ((code == 200) && (result.length > 0)) {
          // add only non-existent event to previously fetched ones
          result.forEach(evt => this.events.set(evt.ID, evt))
          // rebuild displayEvents reactive property
          this.buildEvents(false)
        }
      }
    },

    async hash(string) {
      const utf8 = new TextEncoder().encode(string)
      const hashBuffer = await crypto.subtle.digest('SHA-256', utf8)
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('')
      return hashHex
    },

    async doLogin(user, pass) {
      console.log("logging", user, pass)
      if ( !user || !pass ) {
        return
      }
      const signature = await this.hash(user + pass)
      // Prepare form data to send.
      let formData = new FormData()
      formData.append('user', user)
      formData.append('signature', signature)

      const response = await this.fetchJson('POST', URLs.login, { params: { body: formData }, autoDisconnect: false } )
      if (response.code == 200) {
        this.userData = true
        this.refresh()
      } else if (response.code == 403) {
        setNotification({message: "Utilisateur ou mot de passe incorrect", type: "alert"})
      }
    },

    async init() {
      const { code, message } = await this.fetchJson('GET', URLs.login, { autoDisconnect: false })
      if (code == 200) {
        this.opacity = 0.75
        this.userData = message
        this.refresh()
      } else {
        this.opacity = 1
        this.userData = undefined
      }
    }
  }
}
</script>

<style>
.invisible {
  visibility: hidden
}
.btn-color{
    background-color: #2172e3;
    color: #fff;
}
.profile-image-pic{
    height: 200px;
    width: 200px;
    object-fit: cover;
}
input {
    color: black !important;
}
.field {
    width: 210px;
    padding-left: 16px;    
    padding-right: 16px;    
}

@media all and (min-width: 600px) {
    .add-form {
        width: 500px;
        top: 260px;
        left: 35%;
    }
}

@media (max-width: 992px) {
    .border-md-left {
      border-left: none;
    }
    .border-md-right {
      border-right: none;
    }
}

.notification-list[data-v-e1ef80b2] {
  z-index: 10000;
}
</style>

<script>
export default {
    name: "AuthentView", 
    props: [ 'loading' ],
    emits: [ 'login' ],
    data() { return {
        user : '',
        pass: '',
        submitted: false
    }},
    methods: {
        doLogin() {
            this.submitted = true
            this.$emit('login', this.user, this.pass)
        }
    }
}
</script>

<style scoped>
</style>

<template>
    <div class="row justify-content-md-center">
      <div style="width: 400px">
        <h2 class="text-center text-dark mt-5">
          Identification
        </h2>
        <div class="card my-5 p-2">
          <form @submit.prevent="doLogin">
            <div class="text-center">
              <img
                src="https://cdn.pixabay.com/photo/2016/03/31/19/56/avatar-1295397__340.png"
                class="img-fluid profile-image-pic img-thumbnail rounded-circle my-3"
                width="200px"
                alt="profile"
              >
            </div>

            <div class="mb-3">
              <input
                v-model="user"
                type="text"
                class="form-control black"
                :class="{ 'disabled': loading, 'is-invalid': submitted && user=='' }"
                placeholder="User Name"
              >
              <div class="invalid-feedback">
                Saisissez votre identifiant de connexion
              </div>
            </div>
            <div class="mb-3">
              <input
                v-model="pass"
                type="password"
                class="form-control black"
                :class="{ 'disabled': loading, 'is-invalid': submitted && pass=='' }"
                placeholder="password"
              >
              <div class="invalid-feedback">
                Saisissez votre mot de passe
              </div>
            </div>

            <div class="text-center">
              <button
                type="submit"
                :class="{ disabled : loading }"
                class="btn btn-color px-5 mb-5 w-100"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
</template>
